
import { mapState, mapMutations } from "vuex";
import { getUrlWithFormat } from "@/utils/formatImgUrl";

export default {
  components: {
    LoadWraper: () => import("@/components/throbber/LoadWraper"),
    DrupalBlocks: () => import("@/components/widget/DrupalBlocks"),
  },
  head() {
    const blocks = this.content.blocks;
    let image;
    blocks.forEach(function(block) {
      if (block.id === "rcf_core_spotlightblock") {
        image = getUrlWithFormat(block.articles[0].visual.url, '1280x630_og_image')
      }
    });

    let customMeta = {
      metatag: {
        value: {
          title:
            "RCF, radio chrétienne | Actualité, Culture et Société, Vie Spirituelle, Psychologie, Écologie et Solidarité, Podcasts",
          description:
            "RCF, radio chrétienne | Actualité, Culture et Société, Vie Spirituelle, Psychologie, Écologie et Solidarité, Podcasts",
          og_image_0: image,
        },
      },
    };
    return this.$headService.buildHead(customMeta, null);
  },
  watch: {
    '$store.state.radio.radioTid': {
      handler(newVal) {
        this.$store.dispatch("navigation/getBlocks", "home_page");
      }
    }
  },
  async fetch({ store, error }) {
    try {
      await store.dispatch("navigation/getBlocks", "home_page");
    } catch (e) {
      error({ statusCode: 404, message: e.message });
    }

  },
  async mounted() {
    if (process.client) {
      const gtmGlobalObject = await this.$gtmService.getGlobalObject("Homepage");
      this.$gtmService.push(gtmGlobalObject);
    }
  },
  computed: {
    ...mapState({
      content: (state) => {
        return state.navigation;
      },
      radioTid: (state) => {
        return state.radio.radioTid
      }
    }),
  },
  methods: {
    ...mapMutations({
      showModal: "modal/showModal",
    }),
    getUrlWithFormat,
  },
};
